.common__section{
    background: linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)),url('../Assets/Images/tour.jpg') no-repeat center center/cover;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.common__section h1{
    font-size: 2.5rem;
    color: #fff;
}